.rewards-body-container-main {
  width: 100%;
}
.rewards-body-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  width: 100%;
}

.optional-header {
  height: 60px;
  background-color: #1f221d;
  color: #8fff00;
  font-family: 'Poppins', sans-serif;
  font-size: x-large;
  font-weight: 550;
  padding: 10px;
}
.reward-body-item {
  width: 50%;
}

#body-photo-rewards {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.rewards-title-text {
  font-family: 'Poppins', sans-serif;
  font-size: xx-large;
  font-weight: 600;
  color: rgb(22, 41, 79);
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 10px;
}
.rewards-headline-text {
  font-family: 'Poppins', sans-serif;
  font-size: x-large;
  font-weight: 600;
  color: rgb(44, 78, 146);
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 5px;
}

#promo-image {
  min-width: 280px;
  max-width: 350px;
  max-height: 350px;
}

.disclaimer {
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: x-small;
}
.disclaimer3 {
  padding-left: 50px;
  padding-right: 50px;
  font-size: x-small;
  color: rgb(133, 133, 133);
}

@media only screen and (max-width: 800px) {
  .rewards-body-container {
    display: flex;
    flex-direction: column;
  }

  .reward-body-item {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rewards-title-text {
    color: rgb(255, 255, 255);
    text-shadow: 0.5px 0.5px grey;
  }
  .rewards-headline-text {
    color: rgb(226, 232, 238);
  }

  .disclaimer {
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: x-small;
  }
  .disclaimer3 {
    padding-left: 30px;
    padding-right: 30px;
    font-size: xx-small;
  }
}

@media only screen and (max-width: 410px) {
  .reward-body-item {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #promo-image {
    max-width: 50%;
  }

  .rewards-title-text {
    font-size: large;
    font-weight: 600;
    color: rgb(207, 207, 209);
    text-shadow: 0.5px 0.5px rgb(29, 27, 27);
  }
  .rewards-headline-text {
    font-size: xx-large;
    font-weight: 600;
    color: rgb(253, 253, 253);
    text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  }
}

@media only screen and (max-width: 560px) {
  #promo-image {
    max-width: 50%;
  }
}

@media only screen and (max-width: 669px) {
  .optional-header {
    height: 86px;
  }
}

@media only screen and (max-width: 354px) {
  .optional-header {
    height: 118px;
  }
}


@media only screen and (max-width: 430px) {
  #promo-image {
    max-width: 55%;
  }

  .rewards-title-text {
    font-size: large;
    font-weight: 600;
    color: rgb(207, 207, 209);
    text-shadow: 0.5px 0.5px rgb(29, 27, 27);
  }
  .rewards-headline-text {
    font-size: xx-large;
    font-weight: 600;
    color: rgb(253, 253, 253);
    text-shadow: 0.5px 0.5px rgb(3, 9, 31);
  }
}
